<template>
  <div class="overlay-menu" :class="{ 'overlay-menu--open': isOpen }">
    <button
      class="overlay-menu__button-toggle"
      type="menu"
      @click.prevent="toggleOverlay"
    >
      <slot name="button-open-content"></slot>
    </button>
    <div @click.prevent="toggleOverlay" class="overlay-menu__shadow"></div>
    <div class="overlay-menu__container">
      <div class="overlay-menu__top-container">
        <button
          @click.prevent="toggleOverlay"
          class="overlay-menu__button-menu"
        >
          <slot name="button-close-content">Luk menu</slot>
        </button>
      </div>
      <div class="overlay-menu__content-container">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { toggleDocumentOverflow } from '@/functions'

export default {
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    isOpen(newValue) {
      toggleDocumentOverflow(newValue)
    },
  },
  methods: {
    toggleOverlay() {
      if (this.isOpen) {
        this.closeOverlay()
      } else {
        this.openOverlay()
      }
    },
    openOverlay() {
      if (this.isOpen) return

      window.history.pushState(null, '', null)

      window.addEventListener('popstate', this.closeOverlay)

      this.isOpen = true
    },
    closeOverlay(isPopstate = false) {
      if (!this.isOpen) return

      if (!isPopstate) {
        window.history.back()
      }

      this.isOpen = false

      window.removeEventListener('popstate', this.closeOverlay)
    },
    handleKeyUp(event) {
      if (!this.isOpen) return
      if (event.keyCode !== 27) return

      this.toggleOverlay()
    },
  },
  created() {
    window.addEventListener('keyup', this.handleKeyUp)
  },
}
</script>

<style lang="scss">
@import 'OverlayMenu.scss';
</style>
